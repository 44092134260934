import { Duration, intervalToDuration, milliseconds, parse } from 'date-fns';
import { TermUnit } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { SESSION_STORAGE_KEY } from './constants';

type DurationLabels = {
  [unit in keyof Duration]: string;
};

export const invalidateCache = () => {
  sessionStorage.removeItem(SESSION_STORAGE_KEY.SUBSCRIPTION);
  sessionStorage.removeItem(SESSION_STORAGE_KEY.FLOW);
};

export const replaceTemplateString = (str: string, data?: object) => {
  if (!data) return str;
  return str.replace(/{{(.*?)}}/g, (k, v) => (data[v] ? data[v] : ''));
};

export type PackageTerm = {
  term: number;
  unit: TermUnit;
};

export type TermLabels = {
  years?: string;
  months?: string;
  days?: string;
};

export const formatTerm = (term: Duration, labels: DurationLabels) => {
  return Object.getOwnPropertyNames(labels)
    .filter((l) => term[l] > 0)
    .slice(0, 2)
    .map((l) => `${term[l]} ${labels[l]}`)
    .join(' ');
};

export const convertDuration = (startDate: string, endDate: string, labels: TermLabels) => {
  const gmtStartDate = new Date(startDate);
  const gmtEndDate = new Date(endDate);

  const duration = intervalToDuration({
    start: new Date(gmtStartDate.getFullYear(), gmtStartDate.getMonth(), gmtStartDate.getDate()),
    end: new Date(gmtEndDate.getFullYear(), gmtEndDate.getMonth(), gmtEndDate.getDate()),
  });

  return formatTerm(duration, labels);
};

export const makeReadableTerm = ({ term, unit }: PackageTerm, labels: TermLabels) => {
  const duration = intervalToDuration({ start: 0, end: milliseconds({ [unit.toLowerCase()]: term }) });
  return formatTerm(duration, labels);
};

declare global {
  interface Window {
    webkit?: {
      messageHandlers: {
        jsHandler:
          | undefined
          | {
              postMessage(message: string): void;
            };
      };
    };
  }

  var jsHandler:
    | undefined
    | {
        postMessage(message: string): void;
      };
}

// This weird part needed in order to strict mode not throwing error on
// un existing variable
const _jsHandler = typeof jsHandler === 'undefined' ? undefined : jsHandler;

export function sendMessage(message: string, source: string = '*') {
  // iOS
  if (window.webkit !== undefined) {
    // it is not real postMessage
    // just some placeholder generated by ios
    window.webkit.messageHandlers.jsHandler.postMessage(message); // NOSONAR - should not be used!
    return;
  }
  // Android
  if (_jsHandler) {
    // it is not real postMessage
    // just some placeholder generated by android
    _jsHandler?.postMessage?.(message); // NOSONAR - should not be used!
    return;
  }
  // Regular
  // ignoring sonar, as we don't know the source for toyota app and it
  // is not possible to take it as we run it in webview which doesn't have parent window
  window.postMessage(message, source); // NOSONAR - should not be used!
}
