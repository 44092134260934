import React, { useMemo } from 'react';
import { ManagePaymentContainer, ManagePaymentProps } from './ManagePayment';
import { PaymentContext } from './PaymentContext';
import { COUNTRY_DETAIL_LOOKUP, SubmitCallback, SubscriptionInfo } from '../../Types';
import { CONFIG_KEYS, SubscriptionProps, WebContent } from '@manageSubscription';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { PayPalScriptOptions } from '@paypal/paypal-js';

export type PaymentContextProviderProps = {
  navigateCancel?: () => void;
  submitCallback: SubmitCallback;
  subscriptionInfo?: SubscriptionInfo;
  send?: Function;
  clientIP?: string;
  managePage?: boolean;
  children?: React.ReactNode;
  content: WebContent;
  subscriptionProps: SubscriptionProps;
} & ManagePaymentProps;

export const PaymentContextProvider: React.FC<PaymentContextProviderProps> = ({
  submitCallback,
  content,
  subscriptionProps,
  subscriptionInfo,
  send,
  managePage = false,
  children,
}) => {
  const paymentProps = {
    subscriptionInfo,
    submitCallback,
    content,
    subscriptionProps,
    send,
    payPalClientId: undefined,
  };

  const {
    config,
    userDetails: {
      billingAddress: { country },
    },
  } = subscriptionProps;

  const payPalClientId = config.get(CONFIG_KEYS.PAYPAL_CLIENT_ID);
  paymentProps.payPalClientId = payPalClientId;

  const SCRIPT_PROVIDER_OPTIONS = useMemo<PayPalScriptOptions>(() => {
    const { currencyCode } = COUNTRY_DETAIL_LOOKUP[country];

    return {
      'client-id': payPalClientId,
      'disable-funding': 'credit,card',
      currency: currencyCode,
      vault: true,
      intent: 'tokenize',
    };
  }, [country, payPalClientId]);

  let paymentComponent = <ManagePaymentContainer managePage={managePage}>{children}</ManagePaymentContainer>;

  if (payPalClientId) {
    paymentComponent = (
      <PayPalScriptProvider options={SCRIPT_PROVIDER_OPTIONS}>{paymentComponent}</PayPalScriptProvider>
    );
  }

  return <PaymentContext.Provider value={paymentProps}>{paymentComponent}</PaymentContext.Provider>;
};
