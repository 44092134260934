import {
  Discount,
  SubscribedPackage,
} from '@cv/portal-cps-lib/subscription/subscription-management/models/package-subscription';
import { OfferType, PackageType, TermUnit } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { SESSION_STORAGE_KEY } from '@components-lib';

export const isAnnualTerm = ({ currentTermUnit, renewalTerm }: SubscribedPackage) =>
  currentTermUnit === TermUnit.Months && renewalTerm >= 12;

export const isPaidPackage = (sPkg: SubscribedPackage) => {
  const PAID_PKG_OFFER_TYPES = [OfferType.Base_discount, OfferType.Promotional];
  return (
    sPkg.packageType === PackageType.Regular &&
    (!sPkg?.discounts?.length || getDiscounts(sPkg.discounts, PAID_PKG_OFFER_TYPES).length > 0)
  );
};

const getDiscounts = (discounts: Discount[], offerTypes: OfferType[]) => {
  return discounts?.filter((d) => offerTypes.includes(d.offerType));
};

export const clearSubscriptionCache = () => {
  sessionStorage.removeItem(SESSION_STORAGE_KEY.FLOW);
  sessionStorage.removeItem(SESSION_STORAGE_KEY.FLOW_LAST_PAGE);
  sessionStorage.removeItem(SESSION_STORAGE_KEY.SUBSCRIPTION);
};
