import React from 'react';

import { EligiblePackageInfo, PackageSubscription, SubscribedPackageInfo } from '../../Types';
import Tab from '../../../components/Tab';
import { Container } from '../../../styled-components/globalStyles';
import ViewPackages, { ViewPackagesProps } from './ViewPackages';
import { getPaidPackage } from '../../utils';
import { extractVariants } from './utils';
import { ContentMap } from '../..';

export type PaidPackagesProps = {
  packages: EligiblePackageInfo[];
  removePaidPackages: () => void;
  termsLabel: ContentMap;
  tenantId: string;
  subscribedPackages: SubscribedPackageInfo[];
  packageSubscriptions: PackageSubscription[];
} & Omit<ViewPackagesProps, 'packages'>;

const PaidPackages = ({
  packages,
  tenantId,
  termsLabel,
  subscribedPackages,
  packageSubscriptions,
  removePaidPackages,
  ...viewPackagesProps
}: PaidPackagesProps) => {
  // Extract package variants
  const variants = React.useMemo(
    () => extractVariants(subscribedPackages, packages, tenantId),
    [subscribedPackages, packages, tenantId],
  );

  if (Object.keys(variants).length === 0) {
    return null;
  }

  const packagesByVariant = React.useMemo(() => Object.entries(variants), [variants]);

  const selectedVariantIndex = () => {
    const current = getPaidPackage(packageSubscriptions, subscribedPackages);
    const keys = Object.keys(variants);
    return current ? keys.map((k) => Number(k)).indexOf(current.variant.initialTerm) : keys.length - 1;
  };

  const [selectedTab, setSelectedTab] = React.useState<number>(selectedVariantIndex());

  const handleTabChange = (index: number) => {
    // clear selections
    removePaidPackages();
    // select tab
    setSelectedTab(index);
  };

  return (
    <Container>
      <Tab active={selectedTab} onTabChanged={handleTabChange}>
        {packagesByVariant.map(([key, value]) => (
          <Tab.TabPane key={key} tabTitle={termsLabel[key]}>
            <ViewPackages
              {...viewPackagesProps}
              subscribedPackages={subscribedPackages}
              packageSubscriptions={packageSubscriptions}
              packages={value}
            />
          </Tab.TabPane>
        ))}
      </Tab>
    </Container>
  );
};

export default PaidPackages;
