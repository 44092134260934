import React, { ChangeEvent } from 'react';
import { Checkbox as MuiCheckbox, CheckboxProps, styled } from '@mui/material';

export const Icon = styled('svg')`
  display: table-cell;
  vertical-align: middle;
  fill: none;
  stroke: ${(props) => props.theme.palette.primary.main};
  stroke-width: 3.5px;
`;

const Checkbox = (props: CheckboxProps) => {
  return (
    <MuiCheckbox
      icon={<Icon viewBox="0 0 26 26" />}
      checkedIcon={
        <Icon viewBox="0 0 26 26">
          <polyline points="22 5 10 19 5 13" />
        </Icon>
      }
      {...props}
    />
  );
};

export default Checkbox;
