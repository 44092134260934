import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import milliseconds from 'date-fns/milliseconds';

import { useApi } from '@api';
import { ACTION_MAP } from '@redux/dataMap';
import { useQuery } from '@tanstack/react-query';

type ModelConnectorProps = {
  apiConnector: {
    api: string;
    errorLabel?: string;
  };
};

// TODO: move this to config?
const MAX_CACHE_AGE = milliseconds({ minutes: 10 });

function ModelConnector(WrappedComponent: React.ElementType) {
  return function ModelConnectorWrapper(props: ModelConnectorProps) {
    const api = useApi();

    const services: Record<string, any> = {
      contacts: api.getContacts.bind(api),
      drivers: api.getDrivers.bind(api),
      subscription: api.getSubscription.bind(api),
      account: api.getAccountDetails.bind(api),
      vehicle: api.getVehicleDetails.bind(api),
      vehicleHealth: api.getVehicleHealth.bind(api),
      storeService: api.storeService,
      myCarLocation: api.getVehicleLocation.bind(api),
      paymentMethod: api.getPaymentMethod.bind(api),
      capableServices: api.getCapableServices.bind(api),
    };

    const { apiConnector } = props;
    const modelKey = apiConnector.api.split('/')[1];

    const dispatch = useDispatch();
    const foundAction: string | undefined = ACTION_MAP[modelKey];

    const locale: string | undefined = api.storeService.getLocale();

    const { data, isLoading, isError, error } = useQuery({
      queryKey: [modelKey],
      queryFn: async () => {
        const { data } = await services[modelKey](locale);
        return data;
      },
      cacheTime: MAX_CACHE_AGE,
    });

    useEffect(() => {
      if (foundAction) {
        dispatch({ type: foundAction, data });
      }
    }, [data, foundAction, dispatch]);

    if (isLoading) {
      return null;
    }

    if (isError) {
      console.error('API Connector error', apiConnector, error);
      return <div className="error">{apiConnector.errorLabel}</div>;
    }

    return <WrappedComponent data={data} {...props} />;
  };
}

export default ModelConnector;
