import { Store } from 'redux';
import { setTenantId } from '@redux/actions/account';
import { IVehicle, setVehicleData, setUserId } from '@redux/actions';
import { setPromoCode, setPromoCodeCriteria } from '@redux/actions/user';
import { PromoCodeCriteria } from '@redux/reducers/user';
import { getTokenExpirationTime, getTokenScope } from '@utils/token';
import { getCookie, setCookie } from '@utils/cookies';
import { MapCenter, VehicleLocation } from '@components/Map/types';

enum TokenTypes {
  ID_TOKEN = 'idToken',
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
}

export default class StoreService {
  constructor(private store: Store) {}

  getAccessToken() {
    return getCookie(TokenTypes.ACCESS_TOKEN) ?? '';
  }

  getIdToken() {
    return sessionStorage.getItem(TokenTypes.ID_TOKEN) ?? '';
  }

  getRefreshToken() {
    return sessionStorage.getItem(TokenTypes.REFRESH_TOKEN) ?? '';
  }

  getScope() {
    const accessToken = getCookie(TokenTypes.ACCESS_TOKEN);

    if (!accessToken) {
      return [];
    }

    return getTokenScope(accessToken);
  }

  setTokens({ accessToken, idToken, refreshToken }: { accessToken?: string; idToken?: string; refreshToken?: string }) {
    if (accessToken) {
      const expirationTime = getTokenExpirationTime(accessToken);
      setCookie(TokenTypes.ACCESS_TOKEN, accessToken, expirationTime);
    }

    if (idToken) {
      // TODO: add functionality to remove this token on expiration time
      sessionStorage.setItem(TokenTypes.ID_TOKEN, idToken);
    }

    if (refreshToken) {
      // Refresh token is useless as we don't have functionality
      // to refresh token right now. For more details see PR:
      // https://a-w2a-bitbkt001lv.na.atxglobal.com:8443/projects/PORJ/repos/nissan-janus-cwp-portal/pull-requests/1332/overview
      sessionStorage.setItem(TokenTypes.REFRESH_TOKEN, refreshToken);
    }
  }

  setUserID(userId: string) {
    this.store.dispatch(setUserId(userId));
  }

  setPromoCode(promoCode: string) {
    this.store.dispatch(setPromoCode(promoCode));
  }

  setPromoCodeCriteria(promoCodeCriteria: PromoCodeCriteria) {
    this.store.dispatch(setPromoCodeCriteria(promoCodeCriteria));
  }

  getUserId() {
    return this.store.getState()?.userReducer?.userId;
  }

  getTenantId() {
    return this.store.getState()?.accountReducer?.tenantId;
  }

  setTenantId(tenantId: string) {
    return this.store.dispatch(setTenantId(tenantId));
  }

  setVehicleData(vehicleData: IVehicle) {
    this.store.dispatch(setVehicleData(vehicleData));
  }

  getVehicleData() {
    return this.store.getState()?.vehicleReducer?.vehicle;
  }

  getLocale() {
    return this.store.getState()?.settingsReducer?.locale;
  }

  getContentfulLocale() {
    return this.store.getState()?.settingsReducer?.contentfulLocale;
  }

  getSubscribedServices() {
    return this.store.getState()?.userReducer?.subscribedServices;
  }

  getMapCenter(): MapCenter {
    return this.store.getState()?.mapReducer.mapCenter;
  }

  getVehicleLocation(): VehicleLocation | undefined {
    return this.store.getState()?.mapReducer.vehicleLocation;
  }

  getUserVehicleInfo() {
    const userInfos = this.store.getState()?.vehicleReducer?.vehicle;
    return userInfos?.nickname || `${userInfos?.year} ${userInfos?.model}`;
  }

  getAccountData() {
    return this.store.getState()?.accountReducer?.account;
  }

  getPromoCode() {
    return this.store.getState()?.userReducer?.promoCode;
  }

  getPromoCodeCriteria() {
    return this.store.getState()?.userReducer?.promoCodeCriteria;
  }
}
