import React, { useState } from 'react';
import { getSelectedPackage, replaceTemplateString } from '../utils';
import { useSelector } from '@xstate/react';
import {
  useFlowMachine,
  useFlowMachineContext,
  useFlowMachineContextFlowStorage,
  useFlowMachineContextSubscriptionProps,
} from '../../Router/RouterContext';
import { ConfirmOrder } from './ConfirmOrder';
import { isMobileDevice } from '@lib-utils';
import { Paragraph } from './styles';
import { FlowEventName } from '@lib-appRouter/flowTypes';

export const ConfirmOrderContainer = () => {
  const [loading, setLoading] = useState(false);
  const flowMachine = useFlowMachine();
  const { packageSubscriptions, subscribedPackages } = useFlowMachineContextFlowStorage();
  const { vehicleDetails } = useFlowMachineContextSubscriptionProps();

  const {
    content: {
      authCodeFormContent,
      confirmOrderHeaderLabel,
      confirmOrderDescriptionLabel,
      confirmOrderEmailLabel,
      confirmOrderUpdateEmergencyContacts,
      doneButtonLabel,
      profileButtonLabel,
      packagesWebContent: { packagePrefixLabel },
      wifiWebContent,
      components,
      assets,
    },
    subscriptionProps,
  } = useFlowMachineContext();
  const { downloadApplication } = components || {};

  const selectedPackage = getSelectedPackage(packageSubscriptions, subscribedPackages);
  const showOtpForm = useSelector(flowMachine, (state) => state.context.showAuthCodeForm);
  const isAuthStatusError = useSelector(flowMachine, (state) => state.context.isAuthStatusError);

  const onSubscriptionCompletion = () => {
    setLoading(true);
    flowMachine.send({ type: FlowEventName.NAVIGATE_FORWARD });
  };
  const header = replaceTemplateString(confirmOrderDescriptionLabel, {
    packagePrefix: packagePrefixLabel,
    packageName: selectedPackage?.marketingName,
  });

  const body = replaceTemplateString(confirmOrderEmailLabel, { packagePrefix: packagePrefixLabel });
  const isMobile = isMobileDevice();
  const hideDownloadAppOnConfirmation = subscriptionProps.hideDownloadAppOnConfirmation;
  const showApp = !hideDownloadAppOnConfirmation || !isMobile;

  return (
    <ConfirmOrder
      assets={assets}
      wifiWebContent={wifiWebContent}
      loading={loading}
      hasPackageSubscriptions={packageSubscriptions?.length > 0}
      confirmOrderHeaderLabel={confirmOrderHeaderLabel}
      doneButtonLabel={showApp ? doneButtonLabel : profileButtonLabel}
      header={header}
      body={body}
      subscriptionProps={subscriptionProps}
      onSubscriptionCompletion={onSubscriptionCompletion}
      showOtpForm={showOtpForm}
      authCodeFormContent={authCodeFormContent}
      isAuthStatusError={isAuthStatusError}
    >
      {showApp
        ? downloadApplication &&
          subscriptionProps.renderContentfulComponent({
            name: 'downloadApplication',
            content: [downloadApplication],
            props: { vehicleData: vehicleDetails },
          })
        : confirmOrderUpdateEmergencyContacts && <Paragraph>{confirmOrderUpdateEmergencyContacts}</Paragraph>}
    </ConfirmOrder>
  );
};
