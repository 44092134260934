import { EligiblePackageInfo, PackageSubscription, SubscribedPackageInfo, VariantInfo } from '../Types';
import { OfferType, PackageType, TermUnit } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import findIndex from 'lodash/findIndex';
import {
  CPS_TIMEZONE,
  DEFAULT_SUBSCRIPTION_LOW_TIER,
  MAX_PKG_TERM_IN_MONTHS,
  MAX_PKG_TIER,
  MIN_PKG_TIER,
  PAID_PKG_OFFER_TYPES,
  VALID_PKG_SUBSCRIPTION_DAYS,
} from './constants';
import { isDefaultPackage, isPaidPackage } from './packageUtils';
import { isHighestTrialSubscription, isTrialPackage } from './trialPackageUtils';
import { TenantsSupportOnlyUpgrade } from './productFlowMap';
import { differenceInDays } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { SubscribedPackage } from '@cv/portal-cps-lib/subscription/subscription-management/models/package-subscription';
import { PreviewOrderResponse } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { determineListPrice } from '@manageSubscription/utils/promoPackageUtils';

export const formatToCpsTZ = (date: Date = new Date()) => formatInTimeZone(date, CPS_TIMEZONE, 'yyyy-MM-dd');

export const hasActiveSubscription = (packages: SubscribedPackageInfo[]) => {
  return packages?.some((pkg) => pkg.active && (isTrialPackage(pkg.variant) || isPaidPackage(pkg.variant)));
};

export const isLastInactivePackageLessThanXDays = (
  subscribedPackages: SubscribedPackageInfo[],
  days = VALID_PKG_SUBSCRIPTION_DAYS,
) => {
  // Check if customer's last inactive subscription is <= 60 days from today
  const lastInactivePkg = subscribedPackages
    ?.filter((sPkg) => !sPkg.active)
    .reduce((highest, current, index) => {
      return index === 0 || current.variant.cancelEffectiveDate > highest.variant.cancelEffectiveDate
        ? current
        : highest;
    }, null);

  if (!lastInactivePkg?.variant.cancelEffectiveDate) {
    return true;
  }
  return differenceInDays(new Date(formatToCpsTZ()), new Date(lastInactivePkg.variant.cancelEffectiveDate)) <= days;
};

export const deDupeFarthestSubscriptions = (packages: SubscribedPackageInfo[]) => {
  if (!packages?.length) {
    return [];
  }
  // De-duplicate packages having farthest endDate
  return packages.reduce((state, current) => {
    let prevIndex = findIndex(state, (x) => x.packageName.toLowerCase() === current.packageName.toLowerCase());
    if (prevIndex === -1) {
      state.push(current);
    } else if (state[prevIndex].variant.endDate < current.variant.endDate) {
      state[prevIndex] = current;
    }
    return state;
  }, []);
};

export const getHighestPaidSubscription = (packages: SubscribedPackageInfo[]) => {
  return getHighestSubscription(packages?.filter((pkg) => pkg.tier !== null && isPaidSubscription(pkg.variant)));
};

export const getHighestSubscription = (subscribedPackages: SubscribedPackageInfo[]) => {
  if (!subscribedPackages?.length) {
    return DEFAULT_SUBSCRIPTION_LOW_TIER;
  }
  return subscribedPackages.reduce((highest, current) => {
    if (!current.tier) {
      current.tier = MIN_PKG_TIER;
    }
    return current.tier < highest.tier ? current : highest;
  }, subscribedPackages[0]);
};

export const isPaidSubscription = (variant: VariantInfo) => {
  if (!variant) {
    return false;
  }
  const { discounts } = variant;
  return !discounts?.length || discounts.filter((d) => PAID_PKG_OFFER_TYPES.includes(d.offerType)).length > 0;
};

export const getPaidPackage = (packages: PackageSubscription[], subscribedPackages: SubscribedPackageInfo[]) => {
  const paidPackage = packages.find((pkg) => isPaidPackage(pkg.variant));
  if (paidPackage) {
    return paidPackage;
  }
  const subscribedPackage = subscribedPackages.find((sPkg) => isHighestPaidSubscription(sPkg));
  if (!subscribedPackage) {
    return;
  }
  const sVariant = subscribedPackage.variant;
  return { ...subscribedPackage, variant: { ...sVariant, id: subscribedPackage.subscriptionPackageId } };
};

export const getSelectedPackage = (
  packages: PackageSubscription[],
  subscribedPackages: SubscribedPackageInfo[],
): PackageSubscription | SubscribedPackageInfo | undefined => {
  const paidPackage = getPaidPackage(packages, subscribedPackages);
  if (paidPackage) {
    return paidPackage;
  }
  const trialPackage = packages.find((pkg) => isTrialPackage(pkg.variant));
  if (trialPackage) {
    return trialPackage;
  }
};

export const isHighestPaidSubscription = (pkg: SubscribedPackageInfo) => {
  return (
    pkg.tier === MAX_PKG_TIER && pkg.variant.initialTerm == MAX_PKG_TERM_IN_MONTHS && isPaidSubscription(pkg.variant)
  );
};

export const canSubscribeToPackages = (
  sPackages: SubscribedPackageInfo[],
  ePackages: EligiblePackageInfo[],
  tenantId: string,
) => {
  // If tenant supports both upgrade and downgrade then return true, so that user can
  // continue subscription
  if (!TenantsSupportOnlyUpgrade[tenantId]) {
    return true;
  }
  // Users cannot subscribe to packages, if they already have highest subscription
  // and has no eligible addOns available for given tenant
  return !(
    sPackages.some((sPkg) => isHighestPaidSubscription(sPkg)) &&
    !ePackages.some((ePkg) => ePkg.packageType === PackageType.Add_on)
  );
};

export const getEligiblePackageWithDiscount = (
  eligiblePackages: EligiblePackageInfo[],
  previewOrderResponse: PreviewOrderResponse,
) => {
  return eligiblePackages?.find((ePkg: EligiblePackageInfo) => {
    const matchingPackage = findMatchingSubscription(previewOrderResponse?.subscribedPackages, ePkg.variant);
    if (!matchingPackage) return false;

    ePkg.variant.discounts = matchingPackage.discounts?.filter((d) => d.offerType === OfferType.Promo_code);
    ePkg.variant.listPrice = determineListPrice({
      ...ePkg.variant,
      discounts: matchingPackage.discounts,
      listPrice: matchingPackage.listPrice,
    });
    ePkg.variant.actualPrice = matchingPackage.amountWithoutTax;
    return true;
  });
};

export const findMatchingSubscription = (subscribedPackages: SubscribedPackage[], variant: VariantInfo) => {
  const { id, initialTerm, initialTermUnit } = variant;
  return subscribedPackages?.find(
    (sPkg) =>
      sPkg.active &&
      sPkg.packageVariantId === id &&
      sPkg.initialTermUnit === initialTermUnit &&
      sPkg.initialTerm === initialTerm,
  );
};

export const isAnnualTerm = ({ currentTermUnit, currentTerm }: SubscribedPackageInfo) => {
  return currentTermUnit === TermUnit.Months && currentTerm === 12;
};

export const containsMonthlyOnly = (sPackages: SubscribedPackageInfo[]) => {
  return sPackages?.every((sPkg) => isPaidPackage(sPkg.variant) && !isAnnualTerm(sPkg));
};

export const containsDefaultsOnly = (sPackages: SubscribedPackageInfo[]) => {
  return sPackages?.every(isDefaultPackage);
};

export const calculateSubscriptionTotals = (subscribedPackages: SubscribedPackageInfo[]) => {
  return subscribedPackages.reduce(
    (totals, pkg) => {
      const taxTotal = Number(pkg.variant.taxTotal);
      const taxAmount = taxTotal && !Number.isNaN(taxTotal) ? taxTotal : 0.0;
      totals.totalPrice += Number(pkg.amountWithoutTax);
      totals.totalTaxes += taxAmount;
      return totals;
    },
    { totalPrice: 0, totalTaxes: 0 },
  );
};

const isHighestSubscription = (pkg: SubscribedPackageInfo) => {
  return isHighestTrialSubscription(pkg) || isHighestPaidSubscription(pkg);
};
