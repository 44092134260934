import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { formatAnalyticsEventName } from '@cv/portal-common-lib';
import useLinkConfig from '@hooks/useLinkConfig';
import LinkButton from './LinkButton';

import { ContentfulFile } from '@app/types/ContentfulComponent';
import { ContentfulImage } from '@cv/webframework-react-components';

import styles from './Link.module.css';

const Icon = ({ icon, position }: { icon?: ContentfulFile; position?: string }) => (
  <ContentfulImage
    className={clsx(styles['LinkIcon-image'], styles[`LinkIconPosition--${position}`])}
    imageData={icon}
  />
);

export type LinkProps = {
  name: string;
  label: string;
  url: string;
  newTab?: boolean;
  navigationLink?: boolean;
  showAsButton?: boolean;
  icon?: ContentfulFile;
  iconPosition?: 'right' | 'left';
  linkConfig?: string;
  analyticsEventName?: string;
};

const Link = ({
  label,
  url: staticUrl,
  newTab,
  navigationLink,
  showAsButton,
  icon,
  iconPosition,
  linkConfig,
  analyticsEventName,
}: LinkProps): JSX.Element => {
  const history = useHistory();
  const url = useLinkConfig(staticUrl, linkConfig);

  const formatEventName = formatAnalyticsEventName(analyticsEventName);
  const handleAnchorTag = () => {
    history.push(`${url}`);
  };

  if (showAsButton) {
    return (
      <LinkButton onClick={handleAnchorTag} formatEventName={formatEventName} label={label} url={url} newTab={newTab} />
    );
  }

  if (navigationLink) {
    return (
      <a className={styles.Link} onClick={handleAnchorTag} data-analyticseventname={formatEventName}>
        {label}
      </a>
    );
  }

  return (
    <a
      className={icon ? styles['LinkIcon'] : styles.Link}
      href={url}
      rel="noreferrer"
      onClick={(event) => {
        event.preventDefault();
        const win = window.open(url, '_blank');
        win?.focus();
      }}
      data-analyticseventname={formatEventName}
    >
      {label}
      {icon && <Icon icon={icon} position={iconPosition} />}
    </a>
  );
};

export default Link;
