import { v4 as uuidv4 } from 'uuid';
import forEach from 'lodash/forEach';
import { Country } from '@cv/portal-cps-lib/agreements/agreement-service/enums';

declare global {
  interface Window {
    _cc: () => [];
  }
}

export const buildAccertifyUrl = (collectorUrl, siteId, transactionId) => {
  const url = new URL(`//${collectorUrl}/cc.js`, document.location.toString());
  url.searchParams.set('ts', new Date().getTime().toString());
  url.searchParams.set('sid', siteId);
  url.searchParams.set('tid', transactionId);

  return url.toString();
};

export const generateTransactionId = () => {
  return uuidv4();
};

export const authorizeSite = (collectorUrl, siteId, transactionId) => {
  if (!(collectorUrl && siteId && transactionId)) return;

  const siteURL = `${document.location.protocol}//${collectorUrl}`;
  const scripts = document.getElementsByTagName('script');

  forEach(scripts, (script) => {
    if (script.src.indexOf(siteURL + '/cc.js') < 0) return;

    const _cc = window._cc || ([] as any);
    _cc.push(['ci', { sid: siteId, tid: transactionId }]);
    _cc.push(['st', 500]);
    _cc.push([
      'sf',
      () => {
        console.log('InAuth Call is successful');
      },
    ]);
    _cc.push(['run', siteURL]);
    document.body.removeChild(script);
  });
  return scripts;
};

export const getPostalCodeRegex = (countryCode) => {
  let postalCodeRegex;
  switch (countryCode) {
    case Country.Usa:
      postalCodeRegex = /^\d{5}([\-]?\d{4})?$/;
      break;
    case Country.Can:
      postalCodeRegex = /^([A-Z][0-9][A-Z])\s*([0-9][A-Z][0-9])$/;
      break;
    case Country.Mex:
      postalCodeRegex = /^\d{5}$/;
      break;
  }
  return postalCodeRegex;
};

export const priceTotal = (subtotal: number | string, tax?: number | string) => {
  let total = Number(subtotal);

  const taxAmount = Number(tax);
  if (!Number.isNaN(taxAmount)) {
    total += taxAmount;
  }

  return total.toFixed(2);
};

export const isNameValid = (name?: string) => !!name && name.toLowerCase() !== 'default';

export const hasCorrectFullName = ({ firstName, lastName }: { firstName?: string; lastName?: string } = {}) =>
  [firstName, lastName].every(isNameValid);
