import { VehicleResponse, VehicleServiceDetails } from '@cv/portal-cps-lib/vehicle/vehicle-management/models';
import { VehicleDetails } from '../types/vehicleDetails';
import { SubscribedServices } from '../types';
import { PROVISIONING_COMPLETE } from '@components/VinStatusPage/constants';

type VehicleFormatter = (data: VehicleResponse, subscribedServices: SubscribedServices[]) => VehicleDetails;

export const formatVehicleDetails: VehicleFormatter = (data, subscribedServices = []) => {
  const vehicleServices: VehicleServiceDetails[] = [...data?.allVehicleServiceDetails.vehicleServiceDetails].sort(
    (serviceObj1: VehicleServiceDetails, serviceObj2: VehicleServiceDetails) =>
      (serviceObj1.displayOrder ? serviceObj1.displayOrder : 999) -
      (serviceObj2.displayOrder ? serviceObj2.displayOrder : 999),
  ); //Few of services don't have displayOrder, setting to max displayOrder
  const subscribedCustomerFacingServices = vehicleServices.filter(
    (serviceObj: VehicleServiceDetails) =>
      serviceObj.customerFacing && checkIsSubscribed(subscribedServices, data.vehicleId, serviceObj.vehicleServiceId),
  );

  const getPropertyWithoutDuplicates = <P extends keyof VehicleServiceDetails>(
    vehicleServices: VehicleServiceDetails[],
    property: P,
  ) =>
    Array.from(
      vehicleServices.reduce((services: Set<VehicleServiceDetails[P]>, serviceObj: VehicleServiceDetails) => {
        services.add(serviceObj[property]);
        return services;
      }, new Set()),
    );

  return {
    ...data,
    vehicleServiceDetails: vehicleServices,
    activeServices: getPropertyWithoutDuplicates(
      subscribedCustomerFacingServices.filter(
        (serviceObj: VehicleServiceDetails) => serviceObj?.provisioningStatus === PROVISIONING_COMPLETE,
      ),
      'vehicleServiceName',
    ),
    services: getPropertyWithoutDuplicates(subscribedCustomerFacingServices, 'vehicleServiceName'),
    marketingServiceNames: getPropertyWithoutDuplicates(subscribedCustomerFacingServices, 'marketingName'),
  };
};

const checkIsSubscribed = (subscribedServices: SubscribedServices[], vehicleId: string, serviceId: string) => {
  return subscribedServices
    .filter((subscribedService) => subscribedService.vehicleId === vehicleId)
    .flatMap((subscribedService) => subscribedService.subscribedServiceIds)
    ?.includes(serviceId);
};
