import React from 'react';
import { Button } from '@components-lib';

export type LinkButtonProps = {
  onClick: () => void;
  formatEventName: string;
  label: string;
  url: string;
  newTab: boolean | undefined;
};

const LinkButton = ({ onClick, formatEventName, label, url, newTab }: LinkButtonProps) => {
  const handleClick = !newTab ? onClick : undefined;
  const button = (
    <Button onClick={handleClick} data-analyticseventname={formatEventName}>
      {label}
    </Button>
  );

  return newTab ? (
    <a href={url} target="_blank" rel="noreferrer">
      {button}
    </a>
  ) : (
    button
  );
};

export default LinkButton;
