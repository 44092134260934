import { EligiblePackageInfo } from '@app/components-lib/manageSubscription/Types';
import {
  CheckoutStepData,
  EventType,
  OrderData,
  PageLoadData,
  PageLoadStartedData,
  UserData,
  UserProfileUpdatedData,
  FormViewedData,
  FormStartedData,
  UserSignedOutData,
  ProductListingDisplayedData,
  ProductAddedToCartData,
  UserSignInErroredData,
} from './EventDataTypes';

type BuildUserSignInErroredData = () => UserSignInErroredData;
const buildUserSignInErroredData: BuildUserSignInErroredData = () => {
  return { event: EventType.UserSignInErrored };
};

type BuildProductAddedToCartDataType = (products: EligiblePackageInfo[]) => ProductAddedToCartData;
const buildProductDataFromEligiblePackages = (p: EligiblePackageInfo) => {
  return {
    price: {
      priceTier: `${p.variant.actualPrice} ${p.variant.initialTermUnit}`,
    },
    productInfo: {
      name: p.packageName,
      package_id: p.id,
      productDiscount: `${p.variant.discounts?.[0]?.discountCode || 'no discount code'} ${p.variant.discounts?.[0]?.amount || 'no discount amount'}`,
      productID: p.id,
      variant_id: p.variant.id,
    },
  };
};
const buildProductAddedToCartData: BuildProductAddedToCartDataType = (products) => {
  return {
    event: EventType.ProductAddedToCartEvent,
    product: products.map(buildProductDataFromEligiblePackages),
  };
};

type BuildProductListingDisplayedDataType = (listings: EligiblePackageInfo[]) => ProductListingDisplayedData;
const buildProductListingDisplayedData: BuildProductListingDisplayedDataType = (listings) => {
  return {
    event: EventType.ProductListingDisplayedEvent,
    listingDisplayed: {
      listing: listings.map(buildProductDataFromEligiblePackages),
    },
  };
};

type BuildFormViewedDataType = (data: { name: string }) => FormViewedData;
const buildFormViewedData: BuildFormViewedDataType = ({ name }) => {
  return {
    event: EventType.FormViewedEvent,
    form: { formName: name },
  };
};

type BuildFormStartedDataType = (data: { name: string }) => FormStartedData;
const buildFormStartedDataData: BuildFormStartedDataType = ({ name }) => {
  return {
    event: EventType.FormStartedEvent,
    form: { formName: name },
  };
};

type BuildUserProfileUpdatedDataType = (data: {
  userId: UserProfileUpdatedData['user']['custKey'];
  email: UserProfileUpdatedData['user']['hashedEmail'];
}) => Promise<UserProfileUpdatedData>;
const buildUserProfileUpdatedData: BuildUserProfileUpdatedDataType = async ({ userId, email }) => {
  async function digestMessage(message: string) {
    const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
    return hashHex;
  }
  const hashedEmail = await digestMessage(email);
  return {
    event: EventType.UserProfileUpdatedEvent,
    user: {
      custKey: userId,
      hashedEmail,
    },
  };
};

type BuildUserSignedOutEventDataType = (data: { userId: UserSignedOutData['user']['custKey'] }) => UserSignedOutData;
const buildUserSignedOutEventData: BuildUserSignedOutEventDataType = (data) => {
  return {
    event: EventType.UserSignedOut,
    user: {
      custKey: data.userId,
    },
  };
};

type BuildUserEventDataType = (data: {
  userId: UserData['user']['custKey'];
  userStatus: UserData['user']['loginStatus'];
}) => UserData;
const buildUserEventData: BuildUserEventDataType = (data) => {
  return {
    event: EventType.UserEvent,
    user: {
      custKey: data.userId,
      loginStatus: data.userStatus,
    },
  };
};

type BuildOrderEventDataType = (data: OrderData['transaction']) => OrderData;
const buildOrderEventData: BuildOrderEventDataType = (data) => {
  return { event: EventType.OrderEvent, transaction: data };
};

type BuildCheckoutStepEventDataType = (data: {
  checkoutStep: string;
  products: {
    priceTier: string;
    name: string;
    packageId: string;
    productId: string;
    variantId: string;
  }[];
}) => CheckoutStepData;
const buildCheckoutStepEventData: BuildCheckoutStepEventDataType = (data) => {
  return {
    event: EventType.CheckoutStepEvent,
    eventDetails: {
      checkoutStep: data.checkoutStep,
    },
    product: data.products.map(({ priceTier, name, packageId, productId, variantId }) => ({
      price: {
        priceTier,
      },
      productInfo: {
        name,
        package_id: packageId,
        productID: productId,
        variant_id: variantId,
      },
    })),
  };
};

type BuildPageLoadEventDataType = () => PageLoadData;
const buildPageLoadEventData: BuildPageLoadEventDataType = () => {
  return { event: EventType.PageLoadEvent };
};

type BuildPageLoadStartedEventDataType = (data: PageLoadStartedData['page']) => PageLoadStartedData;
const buildPageLoadStartedEventData: BuildPageLoadStartedEventDataType = (data) => {
  return { event: EventType.PageLoadStartedEvent, page: data };
};

export const eventTypeBuilderMapping = {
  [EventType.UserEvent]: buildUserEventData,
  [EventType.UserSignedOut]: buildUserSignedOutEventData,
  [EventType.UserSignInErrored]: buildUserSignInErroredData,
  [EventType.OrderEvent]: buildOrderEventData,
  [EventType.CheckoutStepEvent]: buildCheckoutStepEventData,
  [EventType.PageLoadEvent]: buildPageLoadEventData,
  [EventType.PageLoadStartedEvent]: buildPageLoadStartedEventData,
  [EventType.UserProfileUpdatedEvent]: buildUserProfileUpdatedData,
  [EventType.FormViewedEvent]: buildFormViewedData,
  [EventType.FormStartedEvent]: buildFormStartedDataData,
  [EventType.ProductListingDisplayedEvent]: buildProductListingDisplayedData,
  [EventType.ProductAddedToCartEvent]: buildProductAddedToCartData,
};

export class EventDataBuilder<T extends EventType> {
  private event: T;
  constructor(event: T) {
    this.event = event;
  }
  withArgs(...args: Parameters<(typeof eventTypeBuilderMapping)[T]>) {
    return eventTypeBuilderMapping[this.event].apply(null, args);
  }
}
