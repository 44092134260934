import { assign } from 'xstate';
import { log, send } from 'xstate/lib/actions';
import { CreateUserInfo, FcaUser, SxmUser, UpdateUserInfo } from '@manageSubscription/Types';
import { createPinWithSQA, getToken, searchUser, updatePassword } from '../../../../services/shim';
import { ComponentRoutes } from '../../../types';
import { isEmpty } from 'lodash';
import { FormValues, ComponentFlowStateConfig, UserProfileFlowContext } from '../Types';
import { FlowEventName } from '../../../flowTypes';
import { FetchMachineEvent } from '../../../fetchMachine';
import { fetchUserDetails, fetchVehicleDetails } from '../../helpers/UserProfileFlowHelper';
import { updateUserProfileById } from '@lib-services/idm';
import { EventDataBuilder, EventType, closeAnalyticsBracket, sendAnalyticsEvent } from '@lib-components/Analytics';

export const userProfileFlow: ComponentFlowStateConfig<UserProfileFlowContext> = () => ({
  initial: 'searchUser',
  id: ComponentRoutes.userProfile,
  context: {
    queryStatus: 'pending',
  },
  states: {
    searchUser: {
      entry: 'setLoading',
      invoke: {
        id: 'searchUser',
        src: (context) => searchUser(context.subscriptionProps),
        onDone: {
          target: 'handleSearchUser',
          actions: [
            assign({
              searchUserData: (_, event) => event.data,
              createProfile: (_, event) => isEmpty(event.data),
            }),
          ],
        },
      },
    },
    handleSearchUser: {
      always: [
        {
          target: 'loginUser',
          cond: (context) => {
            const { subscriptionProps } = context;
            const { fcaUser, sxmUser } = context.searchUserData || {
              fcaUser: {} as FcaUser,
              sxmUser: {} as SxmUser,
            };
            const { isPinSet, passwordExists, isSecurityQuestionsSet } = fcaUser || {
              isPinSet: false,
              passwordExists: false,
              isSecurityQuestionsSet: false,
            };
            subscriptionProps.userDetails.userId = sxmUser?.sxm_userId;
            const rule = ['enroll', 'completeprofile'].includes(subscriptionProps?.location)
              ? [isPinSet, passwordExists, isSecurityQuestionsSet]
              : [passwordExists];
            if (rule.every(Boolean)) return true;
            return false;
          },
        },
        {
          target: 'waitForInput',
          actions: send({
            type: 'PUSH_HISTORY',
            data: { componentRoute: ComponentRoutes.userProfile },
          }),
        },
      ],
    },
    waitForInput: {
      entry: 'unsetLoading',
      on: {
        LOGIN_USER: 'loginUser',
      },
    },
    loginUser: {
      entry: 'setLoading',
      invoke: {
        id: 'loginUser',
        src: async (context, event) => {
          const { subscriptionProps } = context;
          const values: FormValues = event.data?.values;
          const needPhone = !!event.data?.needPhone;

          assign({ queryStatus: 'loading' });
          try {
            const getTokenArgs = {
              ...(values && {
                firstName: values.firstName,
                lastName: values.lastName,
              }),
              isCreateProfile: context.createProfile,
            };

            if (context.createProfile) {
              getTokenArgs['password'] = values?.password;
            }

            const { access_token: accessToken, refresh_token: refreshToken } = await getToken(
              subscriptionProps,
              getTokenArgs as UpdateUserInfo | CreateUserInfo,
            );

            const userProfileUpdatedEventData = await new EventDataBuilder(EventType.UserProfileUpdatedEvent).withArgs({
              userId: subscriptionProps.userDetails.userId,
              email: subscriptionProps.userDetails.email || '',
            });
            sendAnalyticsEvent(userProfileUpdatedEventData);
            closeAnalyticsBracket();

            const fetchedVehicleDetails = await fetchVehicleDetails(subscriptionProps, accessToken);

            const fetchedUserDetails = await fetchUserDetails(
              subscriptionProps,
              fetchedVehicleDetails.registrationCountry,
              accessToken,
              context.searchUserData,
            );

            const newSubscriptionProps = {
              ...subscriptionProps,
              accessToken,
              userDetails: fetchedUserDetails,
              vehicleDetails: fetchedVehicleDetails,
            };
            if (values?.pin) {
              await createPinWithSQA(newSubscriptionProps, {
                pin: values.pin,
                questions: [
                  {
                    answer: values.answer,
                    question: values.question,
                  },
                ],
              });
            }

            if (!context.createProfile && values?.password) {
              await updatePassword(newSubscriptionProps, {
                password: values.password,
              });
            }

            if (needPhone && values.primaryPhone && values.primaryPhoneType) {
              const { getPhoneTypeShortcut } = subscriptionProps;

              await updateUserProfileById(newSubscriptionProps, {
                primaryPhone: values.primaryPhone,
                primaryPhoneType: values.primaryPhoneType,
              }).then(({ primaryPhone: number, primaryPhoneType: type }) => {
                fetchedUserDetails.primaryPhone = {
                  number,
                  type,
                  typeShortcut: getPhoneTypeShortcut(values.primaryPhoneType),
                };
              });
            }

            return Promise.resolve({
              subscriptionData: {
                vehicleDetails: fetchedVehicleDetails,
                userDetails: fetchedUserDetails,
                ...(accessToken && { accessToken }),
                ...(refreshToken && { refreshToken }),
              },
            });
          } catch (e) {
            log('User login has failed. Reason: ', e);
            assign({ queryStatus: 'error' });
          }
        },
        onDone: {
          actions: [
            send((_, event) => ({
              type: FlowEventName.SET_SUBSCRIPTION_PROPS,
              data: event.data.subscriptionData,
            })),
            assign({ queryStatus: 'success' }),
            ({ subscriptionProps: { setVehicleData } }, event) =>
              setVehicleData(event.data.subscriptionData.vehicleDetails),
            send({ type: 'loginFinished' }),
          ],
        },
        onError: {
          actions: (_, event) => console.error(event),
        },
      },
      on: {
        loginFinished: 'fetchPackages',
      },
    },
    fetchPackages: {
      entry: FetchMachineEvent.fetchPackages(),
      on: {
        onFetchPackages: {
          actions: ['unsetLoading', 'navigateForward'],
        },
      },
    },
  },
});
