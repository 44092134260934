import { useInterpret, useSelector } from '@xstate/react';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  AnyEventObject,
  BaseActionObject,
  InterpreterFrom,
  ResolveTypegenMeta,
  ServiceMap,
  StateMachine,
  TypegenDisabled,
} from 'xstate';
import { isMobile } from 'react-device-detect';
import { SESSION_STORAGE_KEY, SubscriptionProps } from '@manageSubscription';
import { applicationFlowMachineInitialContext, createApplicationFlowMachine } from './flowMachine';
import { Middleware } from './flowMiddleware';
import { AggregatedFlowContext } from './flows/componentFlow/Types';
import { ApplicationFlowEvent, FlowEventName } from './flowTypes';
import {
  EventDataBuilder,
  EventType,
  closeAnalyticsBracket,
  openAnalyticsBracket,
  sendAnalyticsEvent,
} from '@lib-components/Analytics';
import { ComponentRoutes } from './types';

export const FlowMachineContext = React.createContext<{ flowMachine: FlowMachineType }>(null);

export const ComponentRouteToAnalyticsCheckoutStepNameMap: { [key in ComponentRoutes]?: string } = {
  [ComponentRoutes.userProfile]: 'Account Register',
  [ComponentRoutes.manageTrialPackages]: 'Eligible Trial',
  [ComponentRoutes.termsAndConditions]: 'Term Acceptance',
  [ComponentRoutes.managePaidPackages]: 'Follow-On Package',
  [ComponentRoutes.payment]: 'Payment',
  [ComponentRoutes.reviewOrder]: 'Review',
};

class FlowMachineHistoryEvent {
  callbacks: ((context: AggregatedFlowContext, event: AnyEventObject) => void)[];
  lastRoute: string;
  constructor() {
    this.callbacks = [];
    this.lastRoute = '';
    this.subscribe((context) => {
      closeAnalyticsBracket();
      openAnalyticsBracket(
        new EventDataBuilder(EventType.PageLoadStartedEvent).withArgs({
          customer_flow: context.flow || '',
          oem: context.subscriptionProps.tenantId,
          pageCategory: 'Manage Subscriptions',
          pageName: context.history?.location.pathname || '',
          pageType: '',
          siteCountry: '',
          siteExperience: isMobile ? 'Mobile' : 'Desktop',
          siteLanguage: context.subscriptionProps.locale,
          siteName: '',
          subsection: '',
          subsection2: '',
          subsection3: '',
          vin_mask: context.subscriptionProps?.vehicleDetails?.vin || '',
        }),
      );
    });
    this.subscribe((context, event) => {
      const route = event.data.componentRoute;

      const checkoutStepName = route && ComponentRouteToAnalyticsCheckoutStepNameMap[route as ComponentRoutes];
      checkoutStepName &&
        sendAnalyticsEvent(
          new EventDataBuilder(EventType.CheckoutStepEvent).withArgs({
            checkoutStep: checkoutStepName,
            products: context.flowSessionStorage.packageSubscriptions.map((pkg) => ({
              priceTier: `${pkg.variant.actualPrice} ${pkg.variant.initialTermUnit}`,
              name: pkg.packageName,
              packageId: pkg.variant.id,
              productId: pkg.variant.id,
              variantId: `${pkg.variant.initialTerm} ${pkg.variant.initialTermUnit}`,
            })),
          }),
        );
    });
    this.subscribe((_, event: AnyEventObject) => {
      sessionStorage.setItem(SESSION_STORAGE_KEY.FLOW_LAST_PAGE, event.data.componentRoute);
    });
  }
  subscribe(callback: (context: AggregatedFlowContext, event: AnyEventObject) => void) {
    this.callbacks.push(callback);
  }
  dispatch(context: AggregatedFlowContext, event: AnyEventObject) {
    const route = event.data.componentRoute;
    //prevent dispatching events for the same route multiple times
    if (this.lastRoute === route) return;
    this.lastRoute = route;
    this.callbacks.forEach((cb) => cb(context, event));
  }
}

export type FlowMachineType = InterpreterFrom<StateMachineType>;

type StateMachineType = StateMachine<
  AggregatedFlowContext,
  any,
  ApplicationFlowEvent,
  {
    value: any;
    context: AggregatedFlowContext;
  },
  BaseActionObject,
  ServiceMap,
  ResolveTypegenMeta<TypegenDisabled, AnyEventObject, BaseActionObject, ServiceMap>
>;

export const FlowMachineProvider: React.FC<{
  children: React.ReactNode;
  subscriptionProps: SubscriptionProps;
  context?: Partial<AggregatedFlowContext>;
}> = ({ children, context, subscriptionProps: { tenantId, location } }) => {
  const middleware = useMemo(() => new Middleware(tenantId, location), [tenantId, location]);
  const historyEvent = new FlowMachineHistoryEvent();
  const flowMachine = useInterpret(
    createApplicationFlowMachine(middleware).withContext({
      ...applicationFlowMachineInitialContext,
      ...context,
      skipToRoute: sessionStorage.getItem(SESSION_STORAGE_KEY.FLOW_LAST_PAGE),
    } as AggregatedFlowContext) as any,
  ) as FlowMachineType;
  flowMachine.onTransition((state) => {
    const event = state.event;
    const context = state.context;
    if (event.type === FlowEventName.UNSET_LOADING) {
      sendAnalyticsEvent(new EventDataBuilder(EventType.PageLoadEvent).withArgs());
    }
    if (event.type === FlowEventName.PUSH_HISTORY) {
      historyEvent.dispatch(context, event);
    }
  });

  return <FlowMachineContext.Provider value={{ flowMachine }}>{children}</FlowMachineContext.Provider>;
};

export const useFlowMachine = () => {
  const { flowMachine } = React.useContext(FlowMachineContext);
  return flowMachine;
};

export const useFlowLocation = () => {
  const location = useLocation().pathname;
  return useMemo(() => location.substring(1), [location]);
};

export const useFlowMachineContextContent = () => {
  const flowMachine = useFlowMachine();
  return useSelector(flowMachine, (state) => state.context.content);
};

export const useFlowMachineContextSubscriptionProps = () => {
  const flowMachine = useFlowMachine();
  return useSelector(flowMachine, (state) => state.context.subscriptionProps);
};

export const useFlowMachineContext = () => {
  const flowMachine = useFlowMachine();
  return useSelector(flowMachine, (state) => state.context);
};

export const useFlowMachineContextFlowStorage = () => {
  const flowMachine = useFlowMachine();
  return useSelector(flowMachine, (state) => state.context.flowSessionStorage);
};
