import React, { ReactNode } from 'react';
import { Container } from '../../styled-components/globalStyles';
import { Divider } from './styles';
import Button from '../../components/Button';
import Spinner from '../../components/Spinner';
import AttWifi from '../AttWifi';
import { AuthCodeFormContent, ContentfulAssets, SubscriptionProps, WifiWebContent } from '../Subscription';
import OtpFormConnector from '../../components/OtpForm/OtpFormConnector';
import SuccessfulMessage from '@lib-components/SuccessfulMessage';

interface ConfirmOrderContainer {
  loading?: boolean;
  hasPackageSubscriptions: boolean;
  confirmOrderHeaderLabel: string;
  doneButtonLabel: string;
  header: string;
  body: string;
  subscriptionProps: SubscriptionProps;
  assets: ContentfulAssets;
  wifiWebContent: WifiWebContent;
  showOtpForm: boolean;
  authCodeFormContent: AuthCodeFormContent;
  isAuthStatusError: boolean;
  children: ReactNode;
  onSubscriptionCompletion: () => void;
}

export const ConfirmOrder: React.FC<ConfirmOrderContainer> = ({
  loading,
  hasPackageSubscriptions,
  confirmOrderHeaderLabel,
  doneButtonLabel,
  header,
  body,
  subscriptionProps,
  assets,
  wifiWebContent,
  showOtpForm,
  onSubscriptionCompletion,
  authCodeFormContent,
  isAuthStatusError,
  children,
}) => {
  return (
    <Container>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          {hasPackageSubscriptions && (
            <>
              <SuccessfulMessage
                assets={assets}
                headerText={confirmOrderHeaderLabel}
                subheaderText={header}
                bodyText={body}
              />
              <Divider />
            </>
          )}
          {showOtpForm && (
            <>
              <OtpFormConnector
                subscriptionProps={subscriptionProps}
                isAuthStatusError={isAuthStatusError}
                skipAction={onSubscriptionCompletion}
                authCodeFormContent={authCodeFormContent}
                assets={assets}
              />
              <Divider />
            </>
          )}
          <AttWifi wifiWebContent={wifiWebContent} subscriptionProps={subscriptionProps} assets={assets} />
          {children}
          <Button disabled={loading} onClick={onSubscriptionCompletion} fullWidth>
            {doneButtonLabel}
          </Button>
        </div>
      )}
    </Container>
  );
};
