import { useCallback, useEffect, useState } from 'react';
import { authorizeSite, buildAccertifyUrl, generateTransactionId, useScript } from '@manageSubscription/utils';
import { usePaymentContext } from '../PaymentContext';
import { CONFIG_KEYS } from '@manageSubscription/utils/constants';
import { useUpdatePayment as useUpdatePaymentQuery } from '../../../../services/cps/queries';
import { PaymentMethodDetails } from '../../../Types';

type Options = {
  onDone?: () => void;
};

export const useUpdatePayment = (options: Options = {}) => {
  const { submitCallback, subscriptionProps, send, clientIP } = usePaymentContext();
  const [transactionId, setTransactionId] = useState<string | null>(null);
  const [scriptLoaderStatus, setScriptLoaderSource] = useScript(null);

  const { config } = subscriptionProps;

  const siteId = config.get(CONFIG_KEYS.SITE_ID);
  const collectorUrl = config.get(CONFIG_KEYS.COLLECTOR_URL);
  const source = config.getOemValue(CONFIG_KEYS.TRANS_SOURCE);

  const newTransaction = () => {
    setTransactionId(generateTransactionId());
  };

  // On hook use, set transactionId to load the script
  useEffect(newTransaction, []);

  // On transactionId state change, load script
  useEffect(() => {
    if (!transactionId) return;
    setScriptLoaderSource(buildAccertifyUrl(collectorUrl, siteId, transactionId));
  }, [transactionId]);

  // Authorize site once the script has been loaded
  useEffect(() => {
    if (scriptLoaderStatus !== 'active') return;
    authorizeSite(collectorUrl, siteId, transactionId);
  }, [scriptLoaderStatus]);

  const { mutate, isLoading } = useUpdatePaymentQuery(subscriptionProps);
  const setUpdatedPayment = useCallback(
    (data: Partial<PaymentMethodDetails>) => {
      mutate(data, {
        onSuccess: (response) => {
          submitCallback?.(response);
          send?.({ type: 'unsetLoading' });
          options.onDone?.();
        },
      });
    },
    [send, options.onDone],
  );

  return { scriptLoaderStatus, setUpdatedPayment, source, transactionId, clientIP, newTransaction, isLoading };
};
