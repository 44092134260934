import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { pick } from 'lodash';

import { useApi } from '@api';
import { LoaderBackdrop } from '@components/Loader';
import UpdatePasswordSuccess from './UpdatePasswordSuccess';
import UpdatePasswordVerification from './UpdatePasswordVerification';
import UpdatePasswordSetNewPassword from './UpdatePasswordSetNewPassword';
import { extractPrimaries } from '@utils/labels';
import config from '@config/config';

type SearchParams = {
  OTP?: string;
  email?: string;
  [key: string]: string;
};

enum Step {
  VerificationForm = 'verificationForm',
  SetPassword = 'setPassword',
  Success = 'success',
}
enum Status {
  Default = 'default',
  Loading = 'loading',
  Error = 'error',
  VerificationDisabled = 'verification-disabled',
}

const errorMessages: { [key: string]: string } = {
  surname: 'Ingrese un apallido paterno válido',
  surname2: 'Ingrese un apallido materno válido',
};

const initialSearchParams = {
  OTP: '',
  email: '',
};

const UpdatePassword = ({ labels, passwordRules }: any) => {
  const dict = useMemo(() => extractPrimaries(labels), [labels]);

  const api = useApi();
  const history = useHistory();
  const location = useLocation();
  const [step, setStep] = useState<Step>(Step.VerificationForm);
  const [status, setStatus] = useState<Status>(Status.Default);
  const [searchParams, setSearchParams] = useState<SearchParams>(initialSearchParams);
  const [password, setPassword] = useState<string>('');
  const [user, setUser] = useState({});

  const navigateTo = (path: string) => {
    history.push(`/${path}`);
  };

  const onConfirm = (step?: Step) => {
    step && setStep(step);
  };

  const onValidation = (
    updatedPayload: { [key: string]: string },
    setFieldError: (fieldName: string, message: string) => void,
  ) => {
    const filteredUser: { [key: string]: string } = pick(user, ['surname', 'surname2']);

    let formIsValid = true;
    for (const [key, value] of Object.entries(filteredUser)) {
      if (updatedPayload[key].toLowerCase() !== value.toLowerCase()) {
        setFieldError(key, errorMessages[key]);
        formIsValid = false;
      }
    }
    if (formIsValid) {
      onConfirm(Step.SetPassword);
    }
  };

  const onPasswordUpdate = async (formPassword?: string, setSubmitting?: (isSubmitting: boolean) => void) => {
    formPassword && setPassword(formPassword);

    try {
      setStatus(Status.Loading);
      const payload = {
        input: {
          code: searchParams.OTP,
          password: formPassword || password,
          version: user?.version || '*',
        },
      };

      const { data } = await api.updatePasswordData(payload, user?._id, config.getTenantId());
      setSubmitting && setSubmitting(false);
      if (data?.success) {
        onConfirm(Step.Success);
      }
      setStatus(Status.Default);
    } catch (error) {
      // display error message from POO
      setSubmitting && setSubmitting(false);
      setStatus(Status.Default);
    }
  };

  useEffect(() => {
    const getUserData = async () => {
      const searchParams = new URLSearchParams(location.search);
      const email = searchParams.get('email') || '';

      const params: SearchParams = {};
      searchParams.forEach((value, key) => {
        params[key] = value;
      });

      // Hide search parameters from the user
      history.replace({ search: '' });

      setSearchParams(params);

      try {
        setStatus(Status.Loading);
        const { data } = await api.queryAccount({ filter: { userName: email }, tenantId: config.getTenantId() });

        if (data.resultCount) {
          setUser(data.result[0]);
          setStatus(Status.Default);
        } else {
          setStatus(Status.VerificationDisabled);
        }
      } catch (e) {
        setStatus(Status.VerificationDisabled);
      }
    };

    getUserData();
  }, []);

  return (
    <>
      {status === Status.Loading && <LoaderBackdrop />}

      {step === Step.VerificationForm && (
        <UpdatePasswordVerification
          isDisabled={status === Status.VerificationDisabled}
          onValidation={onValidation}
          labels={dict}
        />
      )}

      {step === Step.SetPassword && (
        <UpdatePasswordSetNewPassword onPasswordUpdate={onPasswordUpdate} labels={dict} passwordRules={passwordRules} />
      )}

      {step === Step.Success && <UpdatePasswordSuccess onClick={() => navigateTo('login')} labels={dict} />}
    </>
  );
};

export default UpdatePassword;
