import provinces from 'provinces-ca';
import ISO3166 from 'iso-3166-standard';
import { Address, SubscriptionProps } from '@manageSubscription/Subscription/SubscriptionProps';
import { CustomFields } from '@api';

export type FormValues = CustomFields & {
  firstName?: string;
  lastName?: string;
  street__mailing: string;
  street__mailing2?: string;
  city__mailing: string;
  state__mailing: string;
  country__mailing: string;
  postalCode__mailing: string;
  street__billing: string;
  street__billing2?: string;
  city__billing: string;
  state__billing: string;
  country__billing: string;
  postalCode__billing: string;
};

export type FormattedPayload = CustomFields & {
  billingPostalAddress1: string;
  billingCountry: string;
  billingCity: string;
  billingStateProvince: string;
  billingPostalCode: string;
  billingPostalAddress2: string;
  mailingPostalAddress1: string;
  mailingPostalAddress2: string;
  mailingCity: string;
  mailingStateProvince: string;
  mailingPostalCode: string;
  mailingAddressSameAsHome: boolean;
  givenName?: string;
  surname?: string;
  _id: string;
};

interface FormatPayload {
  values: FormValues;
  userId: string;
  billingSameAsMailing: boolean;
  postalCodeCFDISameAsHomeZipCode: boolean;
  shouldProvideCFDIPostalCode: boolean;
}

export const excludeEmptyFields = (obj) => {
  for (const propName in obj) {
    if ([null, undefined, ''].includes(obj[propName])) {
      delete obj[propName];
    }
  }
  return obj;
};

export const formatPayload = ({
  values,
  userId,
  billingSameAsMailing,
  postalCodeCFDISameAsHomeZipCode,
  shouldProvideCFDIPostalCode,
}: FormatPayload): FormattedPayload => {
  const formattedValues = {
    givenName: values.firstName,
    surname: values.lastName,
    billingPostalAddress1: values.street__billing,
    billingCountry: values.country__billing,
    billingCity: values.city__billing,
    billingStateProvince: values.state__billing,
    billingPostalCode: values.postalCode__billing,
    billingPostalAddress2: values.street__billing2,
    mailingPostalAddress1: values.street__mailing,
    mailingPostalAddress2: values.street__mailing2,
    mailingCity: values.city__mailing,
    mailingStateProvince: values.state__mailing,
    mailingPostalCode: values.postalCode__mailing,
    mailingAddressSameAsHome: false,
    customField02: values.customField02,
    customField03: values.customField03,
    customField04:
      postalCodeCFDISameAsHomeZipCode && shouldProvideCFDIPostalCode
        ? values.postalCode__mailing
        : values.customField04,
    postalCodeCFDISameAsHomeZipCode: false,
    _id: userId,
  };

  if (!billingSameAsMailing) return excludeEmptyFields(formattedValues);

  return excludeEmptyFields({
    ...formattedValues,
    billingPostalAddress1: values.street__mailing,
    billingPostalAddress2: values.street__mailing2,
    billingCountry: values.country__mailing,
    billingCity: values.city__mailing,
    billingStateProvince: values.state__mailing,
    billingPostalCode: values.postalCode__mailing,
  });
};

export const hasBillingInformation = (billingAddress: Partial<Address> = {}) => {
  return !!(billingAddress.address1 && billingAddress.city && billingAddress.postalCode && billingAddress.state);
};

export type SubmitOptions = {
  payload: FormattedPayload;
  subscriptionProps: SubscriptionProps;
  setSubscriptionProps: (props: SubscriptionProps) => void;
  setDisplayPayment: (display: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  needCorrectFullName: boolean;
};

export const handleSubmit = async ({
  payload,
  subscriptionProps,
  setSubscriptionProps,
  setDisplayPayment,
  setIsLoading,
  needCorrectFullName,
}: SubmitOptions) => {
  const {
    givenName,
    surname,
    billingStateProvince,
    billingPostalAddress1,
    billingPostalAddress2,
    billingCity,
    billingPostalCode,
    billingCountry,
  } = payload;

  setIsLoading(true);
  try {
    await subscriptionProps.updateAccount(payload, subscriptionProps.accessToken);
    setSubscriptionProps({
      ...subscriptionProps,
      userDetails: {
        ...subscriptionProps.userDetails,

        ...(needCorrectFullName ? { firstName: givenName, lastName: surname } : {}),

        billingAddress: {
          address1: billingPostalAddress1,
          address2: billingPostalAddress2,
          state: billingStateProvince,
          city: billingCity,
          postalCode: billingPostalCode,
          country: billingCountry,
        },
      },
    });
    setDisplayPayment(true);
  } catch (err) {
    console.log('ERROR', err);
  } finally {
    setIsLoading(false);
  }
};

export const stateSelector = (locale: string, mexicoStatesDictionary?: {
  [key: string]: string;
}) => {
  switch (locale) {
    case 'en-US': {
      return ISO3166.getSubdivisions('USA').reduce((newArray, state) => {
        const { name, divisionCode } = state;
        if (name.length && divisionCode.length) {
          if (state.divisionCode === 'DC') {
            newArray.push({ abbreviation: divisionCode, name: 'Washington DC' });
          }
          if (name.length && divisionCode.length) {
            newArray.push({ abbreviation: divisionCode, name });
          }
        }

        return newArray;
      }, []);
    }
    case 'en-CA': {
      return provinces;
    }
    case 'fr-CA': {
      return provinces;
    }
    case 'es-MX': {
      return mexicoStatesDictionary
        ? Object.entries(mexicoStatesDictionary).map(([abbreviation, name]) => ({ abbreviation, name }))
        : [];
    }
  }
};
