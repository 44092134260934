import { OfferType } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { SubscribedPackageInfo } from '../Types';
import { TenantIds } from '@manageSubscription/utils/productFlowMap';

export const MIN_PKG_TIER = 999;
export const MAX_PKG_TIER = 1;
export const MAX_PKG_TERM_IN_MONTHS = 12;
export const ALLOWED_PAID_PKG_VARIANTS = [1, 12];

export const PAID_PKG_OFFER_TYPES = [
  OfferType.Base_discount,
  OfferType.Promotional,
  OfferType.PromotionalClassAVariableTerm,
];
export const DEFAULT_SUBSCRIPTION_LOW_TIER = {
  tier: MIN_PKG_TIER,
  variant: { id: '', initialTerm: -1 },
} as SubscribedPackageInfo;

export const VALID_PKG_SUBSCRIPTION_DAYS = 60;
export const EMPTY_SPACE_REGEX = /\s+/g;
export const CPS_TIMEZONE = 'America/Chicago';

export enum SESSION_STORAGE_KEY {
  FLOW = 'MANAGE_SUBSCRIPTION_COMPONENT',
  SUBSCRIPTION = 'SUBSCRIPTION_PROPS',
  FLOW_LAST_PAGE = 'FLOW_LAST_PAGE',
}

export enum ACCOUNT_SOURCE {
  CUSTOMER = 'Customer',
  DEALER = 'Dealer',
}

export const APPROVED_TENANTIDS = ['fca'];

export const EXCLUDE_AGREEMENT_TAXES_FOR_TENANTS = [TenantIds.Subaru];

export enum CONFIG_KEYS {
  SXM_KEY_CLIENT_ID = 'SXM_KEY_CLIENT_ID',
  SXM_KEY_URL = 'SXM_KEY_URL',
  TRANS_SOURCE = 'SOURCE',
  APPLE_PAY_DOMAIN = 'APPLE_PAY_DOMAIN',
  APPLE_PAY_MERCHANT_ID = 'APPLE_PAY_MERCHANT_ID',
  PAYPAL_CLIENT_ID = 'PAYPAL_CLIENT_ID',
  SITE_ID = 'INAUTH_SITE_ID',
  APP_ID = 'APP_ID',
  SXM_CLIENT_ID = 'SXM_CLIENT_ID',
  SXM_BRAND_NAME = 'SXM_BRAND_NAME',
  MARKETING_CLIENT_ID = 'MARKETING_CLIENT_ID',
  COLLECTOR_URL = 'COLLECTOR_URL',
  SOURCE_CHANNEL = 'SOURCE_CHANNEL',
  SHIM_SEARCH_USER_URL = 'SHIM_SEARCH_USER_URL',
  SHIM_UPDATE_PASSWORD_URL = 'SHIM_UPDATE_PASSWORD_URL',
  SHIM_GET_TOKEN_URL = 'SHIM_GET_TOKEN_URL',
  SHIM_CREATE_PIN_URL = 'SHIM_CREATE_PIN_URL',
  SHIM_ENROLL_URL = 'SHIM_ENROLL_URL',
  SCN_WIFI_ELIGIBLITY_URL = 'SCN_WIFI_ELIGIBLITY_URL',
  SCN_WIFI_SUBSCRIPTION_URL = 'SCN_WIFI_SUBSCRIPTION_URL',
  USER_BILLING_ADDRESS_LINE_1 = 'USER_BILLING_ADDRESS_LINE_1',
  USER_BILLING_ADDRESS_CITY = 'USER_BILLING_ADDRESS_CITY',
  USER_BILLING_ADDRESS_POSTAL_CODE = 'USER_BILLING_ADDRESS_POSTAL_CODE',
  USER_BILLING_ADDRESS_STATE = 'USER_BILLING_ADDRESS_STATE',
  NISSAN_TOKEN_LOGIN_URL = 'NISSAN_TOKEN_LOGIN_URL',
  NISSAN_TOKEN_EXCHANGE_URL = 'NISSAN_TOKEN_EXCHANGE_URL',
  SHIM_LINK_USER = 'SHIM_LINK_USER',
  USER_API_URL_BASE = 'USER_API_URL_BASE',
  ADOBE_ANALYTICS_URL = 'ADOBE_ANALYTICS_URL',
}
